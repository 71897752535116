<template>
  <div>
    <button @click="ActionSetAmount()">
      set amount
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      options: {},
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    ...mapActions('payment', ['ActionSetAmount'])
  },
}
</script>
